
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { TableDefinition } from '@/types';
import RoleService from '@/services/RoleService';
import useValidator from '@/validation/useValidator';
import Screen from '@/components/layout/Screen.vue';
import ApplicationUser from '@/domain/ApplicationUser';
import UserService from '@/services/UserService';
import UserForm from '@/modules/master-data/views/users/components/UserForm.vue';
import FormError from '@/components/FormError.vue';
import Role from '@/domain/Role';
import Location from '@/domain/Location';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import router from '@/router';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import { useNotification } from '@/composable/useNotifications';
import { StaticLocationType } from '@/domain/LocationType';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { normalizeDate } from '@/functions/date';

type State = {
    users: Array<ApplicationUser>;
    loading: boolean;
    showAddUser: boolean;
    showEditUser: boolean;
    newUser: ApplicationUser;
    selectedUser: ApplicationUser;
    availableRoles: Array<Role>;
    availableLocations: Array<Location>;
};

export default defineComponent({
    name: 'user-admin-screen',
    components: {
        BModal,
        Screen,
        UserForm,
        FormError,
        BAdvancedTable,
    },
    setup() {
        const userService = new UserService();
        const roleService = new RoleService();
        const { locationStore } = masterDataStore.getInstance();
        const { confirm } = useDialogBox();
        const { showErrorList } = useNotification();

        const { validateForm, validationResult, clearResults } = useValidator<ApplicationUser>('application-user');

        const state = reactive<State>({
            users: [],
            showAddUser: false,
            showEditUser: false,
            loading: true,
            newUser: new ApplicationUser(),
            selectedUser: new ApplicationUser(),
            availableRoles: [],
            availableLocations: [],
        });

        async function fetchUsers() {
            const usersResponse = await userService.getAllUsers();
            if (usersResponse.success) {
                state.users = usersResponse.users;
            }
        }

        async function fetchRoles() {
            const rolesResponse = await roleService.getAllRoles();
            if (rolesResponse.success) {
                state.availableRoles = rolesResponse.roles;
            }
        }

        onBeforeMount(async () => {
            state.availableLocations = locationStore.locations.filter((x) => x.type.staticTypeIdentifier !== StaticLocationType.BuiltIn);
            await fetchUsers();
            await fetchRoles();
            state.loading = false;
        });

        function mapUsersTable(items: Array<ApplicationUser>): Array<any> {
            return items.map((i) => ({
                id: i.id,
                username: i.username,
                firstName: i.firstName,
                lastName: i.lastName,
                locations: i.locations.map((location) => location.name),
                loginType: i.loginType,
                userRoles: i.userRoles.map((role) => role.name),
                forkliftCertified: i.forkliftCertified,
                certExpirationDate: normalizeDate(i.certExpirationDate),
            }));
        }

        const table = computed(
            (): TableDefinition<any> => ({
                items: mapUsersTable(state.users),
                key: 'userList',
                name: getTitleCaseTranslation('core.domain.users'),
                columnDefinition: [
                    {
                        key: 'username',
                        label: getTitleCaseTranslation('core.domain.username'),
                        searchable: true,
                    },
                    {
                        key: 'firstName',
                        label: getTitleCaseTranslation('core.domain.firstName'),
                        searchable: true,
                    },
                    {
                        key: 'lastName',
                        label: getTitleCaseTranslation('core.domain.lastName'),
                        searchable: true,
                    },
                    {
                        key: 'forkliftCertified',
                        label: getTitleCaseTranslation('core.domain.forkliftCertified'),
                        searchable: true,
                    },
                    {
                        key: 'certExpirationDate',
                        label: getTitleCaseTranslation('core.domain.forkliftCertificationExpiration'),
                        searchable: true,
                    },
                ],
            }),
        );

        function closeModal() {
            state.newUser = new ApplicationUser();
            state.selectedUser = new ApplicationUser();
            state.loading = false;
            clearResults();
        }

        function openAdd() {
            state.showAddUser = true;
        }

        function openEdit(user: ApplicationUser) {
            const userCopy = new ApplicationUser(state.users.find((x) => x.username === user.username));

            if (userCopy) {
                state.selectedUser = userCopy;
                state.showEditUser = true;
            }
        }

        async function openUserHistory(user: ApplicationUser) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.USER, entityId: user.id },
            });
        }

        function handleUserCreateResponse(response: ApplicationUser) {
            state.users.unshift(response);
            state.newUser = new ApplicationUser();
            state.showAddUser = false;
        }

        async function linkAppUserToAuthServer(newUser: ApplicationUser) {
            await userService.linkAppUserToAuthServer(newUser);
        }

        async function saveNewUser(): Promise<ApplicationUser | undefined> {
            validateForm(state.newUser);
            if (!validationResult.isValid) {
                return undefined;
            }

            state.loading = true;

            if ((state.newUser.forkliftCertified)&& (!state.newUser.certExpirationDate || state.newUser.certExpirationDate < new Date(Date.now()))) {
                showDateWarning();
                state.loading = false;
                return undefined;
            }

            if (!state.newUser.email) {
                if (state.newUser.username.includes('@')) {
                    state.newUser.email = state.newUser.username;
                } else {
                    state.newUser.email = `${state.newUser.username}@orbislocal.com`;
                }
            }

            let response: DataAccessResponse<ApplicationUser>;

            switch (state.newUser.loginType) {
            case 'active-directory':
                response = await userService.createNewActiveDirectoryUser(state.newUser);
                break;
            case 'sso':
                response = await userService.createNewSSOUser(state.newUser);
                break;
            default:
                response = await userService.createNewLocalUser(state.newUser);
                break;
            }

            if (response.success) {
                handleUserCreateResponse(response.data);
                await linkAppUserToAuthServer(response.data); // TODO: What if this fails?
            } else if (response.errorList) {
                showErrorList(Object.values(response.errorList));
            }

            state.loading = false;
            return response.success ? response.data : undefined;
        }

        async function showTemporaryPassword(tempPwd: string): Promise<boolean> {
            const result = await confirm({
                title: 'Temporary Password',
                message: `The user's temporary password is: <strong>${tempPwd}</strong>`,
                vHtml: true,
            });
            return result;
        }

        async function showDateWarning() {
            const validationMessage = await confirm({
                title: getTitleCaseTranslation('core.domain.forkliftCertificationDate'),
                message: getTranslation('core.domain.forkliftCertificationMessage'),
                vHtml: true,
            });
            return validationMessage;
        }

        async function saveUserAndShowTempPassword() {
            const newUser = await saveNewUser();

            if (newUser && newUser.password) {
                await showTemporaryPassword(newUser.password);
            }
        }

        function handleUserUpdateResponse(updatedUser: ApplicationUser) {
            const selectedUserIndex = state.users.findIndex((user) => user.id === state.selectedUser.id);
            state.users[selectedUserIndex] = updatedUser;
            state.loading = false;
            state.selectedUser = new ApplicationUser();
            state.showEditUser = false;
        }

        async function updateUser() {
            validateForm(state.selectedUser);

            if (validationResult.isValid) {
                state.loading = true;

                if ((state.selectedUser.forkliftCertified) && (!state.selectedUser.certExpirationDate || state.selectedUser.certExpirationDate < new Date(Date.now()))) {
                    showDateWarning();
                }
                else {
                    const updateResponse = await userService.updateUser(state.selectedUser);

                    if (updateResponse.success) {
                        handleUserUpdateResponse(state.selectedUser);
                    }
                }
                state.loading = false;
            }
        }

        async function deleteUser(user: ApplicationUser) {
            const shouldDelete = await confirm({
                title: getTitleCaseTranslation('core.button.deleteUser'),
                message: getTranslation('core.common.deleteThisUser'),
            });

            if (shouldDelete) {
                state.loading = true;
                const deleteResponse = await userService.deleteUser(user.id);
                if (deleteResponse.success) {
                    state.users = state.users.filter((users) => users.id !== user.id);
                }
            }

            state.loading = false;
        }

        async function resetPassword(user: ApplicationUser) {
            const result = await confirm({
                title: getTitleCaseTranslation('core.button.resetPassword'),
                message: `${getTranslation('core.validation.areYouSureResetPasswordFor')} ${user.username}`,
            });

            if (result) {
                state.loading = true;
                const resetPasswordResponse = await userService.resetPassword(user.username);

                if (resetPasswordResponse.success) {
                    showTemporaryPassword(resetPasswordResponse.data);
                }

                state.loading = false;
            }
        }

        return {
            state,
            openAdd,
            openEdit,
            openUserHistory,
            table,
            saveUserAndShowTempPassword,
            closeModal,
            validationResult,
            deleteUser,
            updateUser,
            getTitleCaseTranslation,
            showTemporaryPassword,
            resetPassword,
            showDateWarning,
        };
    },
});
