
import {
    computed, defineComponent, PropType, onBeforeMount,
} from 'vue';
import ApplicationUser from '@/domain/ApplicationUser';
import Role from '@/domain/Role';
import BMultiSelect from '@/components/BMultiSelect.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import Location from '@/domain/Location';
import { MasterDataStore } from '@/modules/master-data/store/MasterDataStore';
import TextInput from '@/components/inputs/TextInput.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import DateInput from '@/components/inputs/DateInput.vue';

export default defineComponent({
    name: 'user-form',
    components: {
        BMultiSelect,
        BButton,
        TextInput,
        BFormCheckbox,
        DateInput,
    },
    props: {
        modelValue: {
            type: Object as PropType<ApplicationUser>,
            default: () => {},
        },
        availableRoles: {
            type: Array as PropType<Array<Role>>,
            default: () => [],
        },
        availableLocations: {
            type: Array as PropType<Array<Location>>,
            default: () => [],
        },
    },
    emits: ['update:modelValue'],

    setup(props) {
        const masterDataStore = MasterDataStore.getInstance();
        const { locationTypes } = masterDataStore.configStore;
        const existingUser = !!props.modelValue.id;

        const locationTypeFilterProps = {
            name: 'Location Type',
            key: 'type',
        };

        onBeforeMount(() => {
            // set default values
            if (!props.modelValue.forkliftCertified) {
                //clears out the date picker if there's an old date in the database
                props.modelValue.certExpirationDate = undefined;
            }
            props.modelValue.loginType = props.modelValue.loginType || 'local';
        });

        const locationTypeStrings = computed(() => {
            const strings: string[] = [];
            locationTypes.forEach((locationType) => {
                strings.push(locationType.toString());
            });
            return strings;
        });

        function watchForkliftCert() {
            //this will clear the value of the date picker if we change the cert status
            props.modelValue.certExpirationDate = undefined;
        };

        function selectUserType(value: ApplicationUser['loginType']) {
            props.modelValue.loginType = value;
        }

        return {
            locationTypeStrings,
            locationTypeFilterProps,
            selectUserType,
            existingUser,
            getTitleCaseTranslation,
            getTranslation,
            watchForkliftCert,
        };
    },
});

