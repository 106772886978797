import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-063ab394"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "content-loading"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "content-loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_form_error = _resolveComponent("form-error")!
  const _component_user_form = _resolveComponent("user-form")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 100px)",
        loading: _ctx.state.loading,
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addUser')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openUserHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              (row.item.loginType === 'local')
                ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                    key: 0,
                    size: "sm",
                    onClick: () => _ctx.resetPassword(row.item)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.resetPassword')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.deleteUser(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.deleteUser')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array", "loading"]),
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showAddUser,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showAddUser) = $event)),
        centered: "",
        size: "xl",
        title: _ctx.getTitleCaseTranslation('core.common.newUser'),
        onHide: _ctx.closeModal
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.loading,
            onClick: _ctx.saveUserAndShowTempPassword
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          (_ctx.state.showAddUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.state.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("span", null, [
                        _createVNode(_component_b_spinner)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_form_error, { "validation-result": _ctx.validationResult }, null, 8, ["validation-result"]),
                _createVNode(_component_user_form, {
                  modelValue: _ctx.state.newUser,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.newUser) = $event)),
                  "available-roles": _ctx.state.availableRoles,
                  "available-locations": _ctx.state.availableLocations
                }, null, 8, ["modelValue", "available-roles", "available-locations"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "title", "onHide"]),
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showEditUser,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.showEditUser) = $event)),
        centered: "",
        size: "xl",
        title: _ctx.state.selectedUser.displayName,
        onHide: _ctx.closeModal
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.loading,
            onClick: _ctx.updateUser
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          (_ctx.state.showEditUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.state.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("span", null, [
                        _createVNode(_component_b_spinner)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_form_error, { "validation-result": _ctx.validationResult }, null, 8, ["validation-result"]),
                _createVNode(_component_user_form, {
                  modelValue: _ctx.state.selectedUser,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.selectedUser) = $event)),
                  "available-roles": _ctx.state.availableRoles,
                  "available-locations": _ctx.state.availableLocations
                }, null, 8, ["modelValue", "available-roles", "available-locations"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "title", "onHide"])
    ]),
    _: 1
  }))
}