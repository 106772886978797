import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b056ab6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.validationResult.errorList.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationResult.errorList, (error, index) => {
          return (_openBlock(), _createElementBlock("span", null, _toDisplayString(error) + _toDisplayString(index + 1 < _ctx.validationResult.errorList.length ? ', ' : ''), 1))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}