import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2947b972"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "myTab",
  class: "nav nav-pills nav-fill",
  role: "tablist"
}
const _hoisted_2 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_3 = {
  id: "user-details-tab",
  class: "nav-link active",
  "data-bs-toggle": "tab",
  "data-bs-target": "#user-details",
  type: "button",
  role: "tab",
  "aria-controls": "user-details-tab",
  "aria-selected": "true"
}
const _hoisted_4 = { class: "icon-text" }
const _hoisted_5 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_6 = {
  id: "roles-tab",
  class: "nav-link",
  "data-bs-toggle": "tab",
  "data-bs-target": "#roles",
  type: "button",
  role: "tab",
  "aria-controls": "Roles",
  "aria-selected": "false"
}
const _hoisted_7 = { class: "icon-text" }
const _hoisted_8 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_9 = {
  id: "locations-tab",
  class: "nav-link",
  "data-bs-toggle": "tab",
  "data-bs-target": "#locations",
  type: "button",
  role: "tab",
  "aria-controls": "Locations",
  "aria-selected": "false"
}
const _hoisted_10 = { class: "icon-text" }
const _hoisted_11 = {
  id: "myTabContent",
  class: "tab-content"
}
const _hoisted_12 = {
  id: "user-details",
  class: "tab-pane fade show active",
  role: "tabpanel",
  "aria-labelledby": "user-details-tab"
}
const _hoisted_13 = {
  id: "roles",
  class: "tab-pane fade",
  role: "tabpanel",
  "aria-labelledby": "roles-tab"
}
const _hoisted_14 = { class: "item-slot" }
const _hoisted_15 = { class: "item-slot" }
const _hoisted_16 = { class: "nowrap-with-ellipse" }
const _hoisted_17 = {
  id: "locations",
  class: "tab-pane fade",
  role: "tabpanel",
  "aria-labelledby": "locations-tab"
}
const _hoisted_18 = { class: "item-slot" }
const _hoisted_19 = { class: "item-slot" }
const _hoisted_20 = { class: "nowrap-with-ellipse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_button_group = _resolveComponent("b-button-group")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_multi_select = _resolveComponent("b-multi-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createVNode(_component_faicon, {
            icon: "user-circle",
            class: "tab-icon"
          }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.userDetails')), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("button", _hoisted_6, [
          _createVNode(_component_faicon, {
            icon: "lock",
            class: "tab-icon"
          }),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.roles')), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_8, [
        _createElementVNode("button", _hoisted_9, [
          _createVNode(_component_faicon, {
            icon: "map-pin",
            class: "tab-icon"
          }),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.locations')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_b_form, { class: "user-detail-form" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_row, { class: "input-row" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { md: "5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button_group, { class: "loginTypeBtnGroup" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, {
                          cols: "4",
                          style: {"padding-right":"5px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_button, {
                              type: "button",
                              disabled: _ctx.existingUser && _ctx.modelValue.loginType !== 'local',
                              class: _normalizeClass({ 'notSelected': _ctx.modelValue.loginType !== 'local' }),
                              variant: "primary",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectUserType('local')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.localUser')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "class"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_b_col, {
                          cols: "4",
                          style: {"{ padding-left":"5px","padding-right":"5px }"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_button, {
                              type: "button",
                              disabled: _ctx.existingUser && _ctx.modelValue.loginType !== 'sso',
                              class: _normalizeClass({ 'notSelected': _ctx.modelValue.loginType !== 'sso' }),
                              variant: "primary",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectUserType('sso')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.externalUser')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "class"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_b_col, {
                          cols: "4",
                          style: {"padding-left":"5px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_button, {
                              type: "button",
                              disabled: _ctx.existingUser && _ctx.modelValue.loginType !== 'active-directory',
                              class: _normalizeClass({ 'notSelected': _ctx.modelValue.loginType !== 'active-directory' }),
                              variant: "primary",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectUserType('active-directory')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.activeDirectory')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "class"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, { class: "input-row" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.firstName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.firstName) = $event)),
                  disabled: _ctx.modelValue.loginType === 'active-directory' && _ctx.existingUser,
                  label: _ctx.getTitleCaseTranslation('core.domain.firstName'),
                  md: "5"
                }, null, 8, ["modelValue", "disabled", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, { class: "input-row" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.lastName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.lastName) = $event)),
                  disabled: _ctx.modelValue.loginType === 'active-directory' && _ctx.existingUser,
                  label: _ctx.getTitleCaseTranslation('core.domain.lastName'),
                  md: "5"
                }, null, 8, ["modelValue", "disabled", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, { class: "input-row" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.username,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelValue.username) = $event)),
                  disabled: _ctx.existingUser,
                  label: _ctx.getTitleCaseTranslation('core.domain.username'),
                  md: "5"
                }, null, 8, ["modelValue", "disabled", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, { class: "input-row" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.email,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelValue.email) = $event)),
                  disabled: _ctx.modelValue.loginType === 'active-directory',
                  label: _ctx.getTranslation('core.domain.emailOptional') ,
                  md: "5"
                }, null, 8, ["modelValue", "disabled", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, { class: "input-row" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.modelValue.phoneNumber,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelValue.phoneNumber) = $event)),
                  disabled: _ctx.modelValue.loginType === 'active-directory',
                  label: _ctx.getTranslation('core.domain.phoneNumberOptional'),
                  md: "5"
                }, null, 8, ["modelValue", "disabled", "label"])
              ]),
              _: 1
            }),
            (_ctx.modelValue.loginType === 'local')
              ? (_openBlock(), _createBlock(_component_b_row, {
                  key: 0,
                  class: "input-row"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_text_input, {
                      modelValue: _ctx.modelValue.samAccountName,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelValue.samAccountName) = $event)),
                      label: _ctx.getTitleCaseTranslation('core.domain.dashboardUsername'),
                      md: "5"
                    }, null, 8, ["modelValue", "label"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_b_row, { class: "certification-container" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_checkbox, {
                      modelValue: _ctx.modelValue.forkliftCertified,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelValue.forkliftCertified) = $event)),
                      label: _ctx.getTitleCaseTranslation('core.domain.forkliftCertified'),
                      onClick: _ctx.watchForkliftCert
                    }, null, 8, ["modelValue", "label", "onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_date_input, {
                      modelValue: _ctx.modelValue.certExpirationDate,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modelValue.certExpirationDate) = $event)),
                      label: _ctx.getTitleCaseTranslation('core.domain.forkliftCertificationExpiration'),
                      disabled: !_ctx.modelValue.forkliftCertified,
                      clearable: _ctx.modelValue.forkliftCertified
                    }, null, 8, ["modelValue", "label", "disabled", "clearable"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_b_multi_select, {
          modelValue: _ctx.modelValue.userRoles,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modelValue.userRoles) = $event)),
          data: _ctx.availableRoles,
          "display-name": "name",
          "selected-header": _ctx.getTitleCaseTranslation('core.selectOptions.selectedRoles'),
          "unselected-header": _ctx.getTitleCaseTranslation('core.selectOptions.rolesAvailableToSelect'),
          cols: "12",
          class: "roles-selector"
        }, {
          "list-item-unselected": _withCtx((row) => [
            _createElementVNode("div", _hoisted_14, _toDisplayString(row.item.name), 1)
          ]),
          "list-item-selected": _withCtx((row) => [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(row.item.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "data", "selected-header", "unselected-header"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_b_multi_select, {
          modelValue: _ctx.modelValue.locations,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modelValue.locations) = $event)),
          data: _ctx.availableLocations,
          "display-name": "name",
          "selected-header": _ctx.getTitleCaseTranslation('core.selectOptions.selectedLocations'),
          "unselected-header": _ctx.getTitleCaseTranslation('core.selectOptions.locationsAvailableToSelect'),
          "filter-options": _ctx.locationTypeStrings,
          "filter-props": _ctx.locationTypeFilterProps,
          cols: "12",
          class: "locations-selector"
        }, {
          "list-item-unselected": _withCtx((row) => [
            _createElementVNode("div", _hoisted_18, _toDisplayString(row.item.name) + " | " + _toDisplayString(row.item.type.description), 1)
          ]),
          "list-item-selected": _withCtx((row) => [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, _toDisplayString(row.item.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "data", "selected-header", "unselected-header", "filter-options", "filter-props"])
      ])
    ])
  ], 64))
}