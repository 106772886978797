
import { defineComponent, PropType } from 'vue';
import { ValidationResult } from '@/validation/types';

export default defineComponent({
    name: 'form-error',
    props: {
        validationResult: {
            type: Object as PropType<ValidationResult<unknown>>,
        },
    },
    setup() {
        return {};
    },
});
